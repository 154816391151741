import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

var routes = [
  {
    path: '/login',
    component: () => import('../views/userLogin/UserLogin.vue')
  },
  {
    path: '/',
    redirect: '/login'
  },
  {
    path: '/layout',
    component: () => import('@/views/layout/index.vue'),
    redirect: '/userManage',
    children: [
      {
        path: '/userManage',
        component: () => import('@/views/layout/userManage/index.vue'),
      },
      {
        path: '/roleManage',
        component: () => import('@/views/layout/roleManage/index.vue'),
      },
      {
        path: '/permissionGroup',
        component: () => import('@/views/layout/permissionGroup/index.vue'),
      },
      {
        path: '/permissionManage',
        component: () => import('@/views/layout/permissionManage/index.vue'),
      },
    ]
  },
  {
    path: '/wbxt',
    component: () => import('@/views/wbxt/index.vue'),
    redirect: '/wbAccount',
    children: [
      {
        path: '/wbAccount',
        component: () => import('@/views/wbxt/wbAccount/index.vue'),
      },
      {
        path: '/wbRecord',
        component: () => import('@/views/wbxt/wbRecord/index.vue'),
      },
      {
        path: '/wbProject',
        component: () => import('@/views/wbxt/wbProject/index.vue'),
      },
      {
        path: '/device',
        component: () => import('@/views/wbxt/wbRecord/Device.vue'),
      },
      {
        path: '/bzRecord',
        component: () => import('@/views/wbxt/wbRecord/BzRecord.vue'),
      }
    ]
  },
  {
    path: '/selectAdminPortal',
    component: () => import('../views/userLogin/SelectAdminPortal.vue')
  },
  {
    path: '/equipmentType',
    component: () => import('../views/equipment/EquipmentType.vue')
  },
  {
    path: '/projectManage',
    component: () => import('../views/projectManage/index.vue')
  },
  {
    path: '/singleProject',
    component: () => import('../views/singleProject/index.vue')
  },
  {
    path: '/equipSet',
    component: () => import('../views/equipSet/index.vue')
  },
  {
    path: '/singleArea',
    component: () => import('../views/singleArea/index.vue'),
    redirect: '/mapMode',
    children: [
      {
        path: '/mapMode',
        component: () => import('@/views/singleArea/mapMode.vue'),
      },
      {
        path: '/listMode',
        component: () => import('@/views/singleArea/listMode.vue'),
      },
    ]
  },
  {
    path: '/zhbsyy',
    name: 'zhbsyy',
    component: () => import('../views/largeScreen/ScreenIndex.vue')
  },
  {
    path: '/equip',
    name: 'equip',
    component: () => import('../views/largeScreen/ScreenIndex.vue')
  },
  {
    path: '/environment',
    name: 'environment',
    component: () => import('../views/largeScreen/ScreenIndex.vue')
  },
  {
    path: '/people',
    name: 'people',
    component: () => import('../views/largeScreen/ScreenIndex.vue')
  }
]

const router = new VueRouter({
  routes: routes
})

const routerPush = VueRouter.prototype.push
VueRouter.prototype.push = function push (to) {
  return routerPush.call(this, to).catch(error => error)
}

export default router
