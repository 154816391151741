/*
 * @Author: yintiant 1214655576@qq.com
 * @Date: 2023-03-27 03:06:07
 * @LastEditors: yintiant 1214655576@qq.com
 * @LastEditTime: 2023-12-04 00:05:30
 * @FilePath: /xinfa-main/src/utils/commImport.js
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 */
import Vue from 'vue'
// ElementUI
import ElementUI from 'element-ui'
import 'element-ui/lib/theme-chalk/index.css'
import md5 from 'js-md5'
import VueKonva from 'vue-konva'
import router from '@/router/index'
let Base64 = require('js-base64').Base64
import { setToken, setMe } from '@/utils/auth'
Vue.use(ElementUI, { size: 'small' })
Vue.use(VueKonva)

// 系统显示标题
const page = '极点智能路牌控制后台'
Vue.prototype.page = page
// Vue.prototype.basicUrl = 'https://cloud.hnverde.com/iotippapi'
Vue.prototype.basicUrl = 'https://iotippapi.shockman.cn'
Vue.prototype.$md5 = md5
var screenHeight = document.documentElement.clientHeight
var screenWidth = document.documentElement.clientWidth
window.onresize = () => {
  return (() => {
    screenHeight = document.documentElement.clientHeight
    screenWidth = document.documentElement.clientWidth
  })()
}
Vue.prototype.screenHeight = screenHeight
Vue.prototype.screenWidth = screenWidth

import BaiduMap from 'vue-baidu-map'
Vue.use(BaiduMap, { ak: '1PrVkLNqct3t29DXHKhvQa9a93Q4bQlR' })

import * as echarts from 'echarts'
Vue.prototype.$echarts = echarts
router.beforeEach((to, from, next) => {
  if (to.path !== '/login') {
    next()
  } else {
    if (to.query && to.query.token && to.path === '/login') {
      setToken(to.query.token)
      let me = Base64.decode(to.query.token.split('.')[1])
      if (me) {
        me = JSON.parse(me)
        setMe(me)
        if (me.userLevel === 1) {
          next('/selectAdminPortal')
        } else {
          next('/projectManage')
        }
      }
    } else {
      next()
    }
  }
})