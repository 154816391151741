// auth.js
var tokenKey = 'TOKEN'
var meKey = 'ME'

export const getToken = () => {
  const token = window.sessionStorage.getItem(tokenKey)
  if (token && typeof token === 'string') {
    return token
  }
  return ''
}

export const setToken = (token, cb) => {
  window.sessionStorage.setItem(tokenKey, token)
  if (cb) {
    cb()
  }
}
export const removeToken = () => {
  if (getToken()) {
    window.sessionStorage.removeItem(tokenKey)
  }
}

export const setMe = (me) => {
  window.sessionStorage.setItem(meKey, JSON.stringify(me))
}
export const removeMe = () => {
  window.sessionStorage.removeItem(meKey)
}

export const getMe = () => {
  let me = window.sessionStorage.getItem(meKey)
  if (me && me !== 'undefined') {
    me = JSON.parse(me)
  }
  return me
}

