<template>
  <div class="header">
    <div class="left">
      <el-image  :src="require('@/assets/layout/Logo.png')" />
    </div>
    <div class="right">
      <div class="alarm my-icon">
        <el-image fit="scale-down" :src="require('@/assets/layout/icon_news.png')"/>
      </div>
      <div class="user-info">
        <span class="user-avatar my-icon">
          <el-image fit="scale-down" src="https://zos.alipayobjects.com/rmsportal/ODTLcjxAfvqbxHnVXCYX.png" />
        </span>
        <span class="user-name" >{{ username }}</span>
        
				<el-dropdown @command="toLogin">
          <span class="my-icon">
            <i class="el-icon-arrow-down"></i>
          </span>
          <el-dropdown-menu slot="dropdown" @click="toLogin">
            <el-dropdown-item>退出</el-dropdown-item>
          </el-dropdown-menu>
				</el-dropdown>
      </div>
    </div>
  </div>
</template>

<script>
import { getMe, removeMe, removeToken } from '@/utils/auth'
export default {
	name: "myHeader",
	data() {
		return {
			portal: "",
      admin: 1,
      username: ''
		};
	},
	mounted() {
    const me = getMe()
    if (me && me.username) {
      this.username = me.username
    }
  },
	methods: {
    toLogin () {
      this.$confirm('确认退出？', '提示', {
        confirmButtomText: '确认',
        cancelButtomText: '取消',
        type: 'warning',
      }).then(() => {
        this.$router.push('/login')
        removeMe()
        removeToken()
      })
    },
		change(e) {
			console.log(e);
			this.portal = e;
			this.btn();
		},
		btn() {
			var self = this;
			self.$router.push({
				path: this.portal,
				query: {},
			});
		},
	},
};
</script>

<style scoped lang="less">
.header {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  padding: 8px 12px;
  background-color: #fff;
  & > div {
    height: 32px;
  }
  .left {
    flex: 1;
  }
  .right {
    display: flex;
    flex-direction: row;
    align-items: center;
    .alarm {
      margin-right: 8px;
    }
    .user-info {
      display: flex;
      .user-avatar {
        margin-right: 4px;
      }
      .user-name {
        display: inline-block;
        width: 80px;
        text-overflow: ellipsis;
        overflow: hidden;
        white-space: nowrap;
      }
    }
    .my-icon {
      display: inline-block;
      width: 20px;
      height: 20px;
      line-height: 20px;
      text-align: center;
    }
  }
}
</style>
