<template>
  <div id="app">
    <MyHeader v-if="!['/login'].includes(this.$route.path)" />
    <div class="content">
      <router-view/>
    </div>
  </div>
</template>

<script>

import MyHeader from './views/layout/header.vue'
export default {
  name: 'App',
  components: { MyHeader },
}
</script>
<style scoped lang="less">

#app {
  height: 100%;
  width: 100%;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  font-family: "Arial", "Microsoft YaHei", "黑体", "宋体", sans-serif;
  display: flex;
  flex-direction: column;
  background-color: #eee;
  
  .content {
    flex: 1;
  }
}
</style>
<style>
html, body, div, span {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}
</style>
